import React, { Component } from 'react';
import { ProgressBar, Button } from 'react-bootstrap';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import PropTypes from 'prop-types';
import './StudentInfo.css';
import Table from './Table';
import ErrorPopup from './ErrorPopup';
import { abbreviateRecommendedAction } from './StudentList';
import StudentTimeline from './StudentTimeline';

function flashText(element) {
  element.style.backgroundColor = 'rgb(0, 74, 124)';
  element.style.color = 'rgb(255, 255, 255)';
  const bgColors = [0, 74, 124];
  const textColors = [255, 255, 255];
  const animation = setInterval(() => {
    if (bgColors[0] < 148 && element.id !== '') {
      element.style.backgroundColor = `rgb(${bgColors[0] += 3.7}, ${bgColors[1] += 3.5}, ${bgColors[2] += 2.4})`;
      element.style.color = `rgb(${textColors[0] -= 6.375}, ${textColors[1] -= 4.525}, ${textColors[2] -= 3.025})`;
    } else {
      clearInterval(animation);
    }
  }, 16.67);
}

class StudentInfo extends Component {
  constructor(props) {
    super(props);
    this.recSelectRef = React.createRef();
    this.noteRef = React.createRef();
    this.postRecommendedAction = this.postRecommendedAction.bind(this);
    this.updateListRecAction = this.updateListRecAction.bind(this);
    this.updateStudentData = this.updateStudentData.bind(this);
    this.createTableData = this.createTableData.bind(this);
    this.state = {
      recommendationData: [],
      tableData: [],
      timelineData: [],
      defaultStart: moment().endOf('day').subtract(2, 'months'),
      defaultEnd: moment().endOf('day'),
      popupContent: null,
    };
  }

  componentDidUpdate(prevProps) {
    const current = prevProps.currentStudent;
    const next = this.props.currentStudent;
    if (next && (!current || next.ls_learner_id !== current.ls_learner_id)) {
      this.setState({ tableData: [], timelineData: [] }, () => {
        fetch(
          `${process.env.REACT_APP_DOMAIN}/learner-info/${next.ls_learner_id}/`,
          { headers: this.props.headers },
        ).then((response) => {
          if (!response.ok) {
            this.setState({ popupContent: 'Internal server error' });
          } else {
            response.json().then((student) => {
              const timelineData = [];
              let timelineEnd = null;
              if (student.sessions) {
                student.sessions.forEach((session, i) => {
                  const end = moment(session.end);
                  if (timelineEnd < end) {
                    timelineEnd = end;
                  }
                  const passPredict = session.pass_prediction;
                  let className = 'session ';
                  if (passPredict >= 80) {
                    className += 'passSession';
                  } else if (passPredict >= 60) {
                    className += 'undecidedSession';
                  } else if (!passPredict) {
                    className += 'noneSession';
                  } else {
                    className += 'failSession';
                  }
                  const dataPoint = {
                    id: `session${i}`,
                    group: 1,
                    toolTip: `Start: ${moment(session.begin).format('lll')}
                      End: ${moment(session.end).format('lll')}
                      Activities: ${session.activities}
                      Pass prediction at start of session: ${passPredict || 'None'}
                    `,
                    className,
                    start_time: moment(session.begin),
                    end_time: end,
                  };
                  timelineData.push(dataPoint);
                });
              }

              if (student.pre_tests) {
                student.pre_tests.forEach((preTest, i) => {
                  const begin = moment(preTest.begin);
                  if (timelineEnd < begin) {
                    timelineEnd = begin;
                  }
                  const dataPoint = {
                    id: `pre-test${i}`,
                    group: 2,
                    content: <i className={`fa fa-square${preTest.passed ? ' timelinePassPretest' : '-o timelineFailPretest'} fa-fw`} />,
                    toolTip: preTest.passed ? 'Passed the pre-test' : 'Did not pass the pre-test',
                    start_time: begin,
                    end_time: begin,
                  };
                  timelineData.push(dataPoint);
                });
              }

              if (student.final_assessments) {
                student.final_assessments.forEach((final, i) => {
                  const begin = moment(final.begin);
                  if (timelineEnd < begin) {
                    timelineEnd = begin;
                  }
                  const dataPoint = {
                    id: `final${i}`,
                    group: 3,
                    content: <p className="emptyTriangle timelineFailFinal">&#x25B3;</p>,
                    toolTip: 'Did not pass the final',
                    start_time: begin,
                    end_time: begin,
                  };
                  timelineData.push(dataPoint);
                });
              }

              if (!timelineEnd) {
                timelineEnd = moment().endOf('day');
              }

              timelineEnd = moment(timelineEnd).add(1, 'days');

              const dataPoint = {
                id: 'expectedRange',
                group: 1,
                className: 'expectedRange',
                start_time: moment(student.expected_start),
                end_time: moment(student.expected_end),
              };
              timelineData.push(dataPoint);

              this.createTableData(student.recommendations);
              this.setState({
                timelineData,
                defaultStart: moment(timelineEnd).subtract(2, 'months'),
                defaultEnd: timelineEnd,
              });
            });
          }
        }).catch(() => {
          this.setState({ popupContent: 'Could not connect to server' });
        });
      });
    }
  }

  updateListRecAction(recData) {
    const listText = this.props.selectedListRow.childNodes[2];
    if (recData.system_recommendation === recData.instructor_recommendation) {
      listText.textContent = abbreviateRecommendedAction(recData.system_recommendation);
    } else {
      listText.innerHTML = `${abbreviateRecommendedAction(recData.system_recommendation)}<i class="fa fa-exclamation-circle fa-fw" title="Latest system recommendation differs from the latest instructor recommendation." />`;
    }
    listText.textContent = abbreviateRecommendedAction(recData.system_recommendation);
    this.props.currentStudent.recommended_action = recData.sysRecommendation;
    flashText(this.props.selectedListRow);
  }

  updateStudentData(recData) {
    const newRec = Object.assign({}, recData);
    newRec.pass_prediction = this.props.currentStudent.pass_prediction;
    let recommendationData = this.state.recommendationData.slice();
    const latestRec = recommendationData[0];
    if (latestRec) {
      if (latestRec.instructor_recommendation && latestRec.instructor_recommendation_time) {
        recommendationData.unshift(newRec);
      } else {
        recommendationData[0] = newRec;
      }
    } else {
      recommendationData = [newRec];
    }
    this.createTableData(recommendationData);
  }

  postRecommendedAction() {
    let sysRecommendation = '';
    let sysRecommendationTime = '';
    const instructorRecommendation = this.recSelectRef.current;
    const instructorRecommendationText = instructorRecommendation
      .options[instructorRecommendation.selectedIndex].text;
    const latestRec = this.state.recommendationData[0];
    if (latestRec) {
      sysRecommendation = latestRec.system_recommendation;
      sysRecommendationTime = latestRec.system_recommendation_time;
    }
    fetch(`${process.env.REACT_APP_DOMAIN}/recommendation/`, {
      method: 'POST',
      headers: this.props.headers,
      body: JSON.stringify({
        ls_learner_id: this.props.currentStudent.ls_learner_id,
        system_recommendation: sysRecommendation,
        system_recommendation_time: sysRecommendationTime,
        instructor_recommendation: instructorRecommendationText,
        instructor_recommendation_time: moment(),
        note: this.noteRef.current.value,
      }),
    }).then((response) => {
      if (!response.ok) {
        this.setState({ popupContent: 'Internal server error' });
      } else {
        response.json()
          .then((jsonResult) => {
            this.updateStudentData(jsonResult);
            this.noteRef.current.value = null;
            this.updateListRecAction(jsonResult);
          });
      }
    }).catch(() => {
      this.setState({ popupContent: 'Could not connect to server' });
    });
  }

  createTableData(recommendationData) {
    const tableData = [];
    if (recommendationData[0]) {
      recommendationData.forEach((recommendation) => {
        let systemRecomTime = null;
        let instructorRecomTime = null;
        if (recommendation.system_recommendation_time) {
          systemRecomTime = new Date(Date.parse(recommendation.system_recommendation_time)).toLocaleDateString('en-us');
        }
        if (recommendation.instructor_recommendation_time) {
          instructorRecomTime = new Date(Date.parse(recommendation.instructor_recommendation_time)).toLocaleDateString('en-us');
        }
        tableData.push(
          <tr key={recommendation.system_recommendation_time
            + recommendation.instructor_recommendation_time}
          >
            <td>
              <button
                type="button"
                className="dateButton srDateButton"
                onClick={() => this.setVisibleTimes(
                  moment(systemRecomTime).startOf('day'), moment(systemRecomTime).endOf('day'),
                )}
              >
                {systemRecomTime}
              </button>
            </td>
            <td>{recommendation.system_recommendation}</td>
            <td>{recommendation.pass_prediction && `${recommendation.pass_prediction}%`}</td>
            <td>
              <button
                type="button"
                className="dateButton"
                onClick={() => this.setVisibleTimes(
                  moment(instructorRecomTime).startOf('day'), moment(instructorRecomTime).endOf('day'),
                )}
              >
                {instructorRecomTime}
              </button>
            </td>
            <td>{recommendation.instructor_recommendation}</td>
            <td>{recommendation.note}</td>
          </tr>,
        );
      });
    }
    this.setState({ tableData, recommendationData });
  }

  render() {
    const student = this.props.currentStudent;
    const disabled = !this.props.currentStudent;
    let passPredictionBar = <ProgressBar />;
    let passPredictionText = 'Select a student to check the current pass probability.';
    let passPrediction = {};
    if (student) {
      if (student.pass_prediction == null) {
        passPredictionBar = <ProgressBar now={0} />;
        passPredictionText = 'Pass probabilty is not calculated for this student.';
      } else {
        if (student.pass_prediction >= 80) {
          passPrediction = { percentage: student.pass_prediction, state: 'success' };
        } else if (student.pass_prediction < 60) {
          passPrediction = { percentage: student.pass_prediction, state: 'danger' };
        } else {
          passPrediction = { percentage: student.pass_prediction, state: 'warning' };
        }
        passPredictionBar = <ProgressBar bsStyle={passPrediction.state} now={passPrediction.percentage} label={`${passPrediction.percentage}%`} />;
        passPredictionText = `Current pass probability is ${passPrediction.percentage}%`;
      }
    }

    // abbreviate table headers if screen is portrait orientation
    let srLabel = 'System Recommendation';
    let irLabel = 'Instructor Recommendation';
    let passLabel = 'Pass Prediction';
    if (window.innerHeight > window.innerWidth) {
      srLabel = 'System\nRec.';
      irLabel = 'Instructor\nRec.';
      passLabel = 'Pass\nPred.';
    }

    return (
      <div id="studentInfo">
        <div id="headerWrapper">
          <div id="legends">
            <div className="column">
              <i className="fa fa-check-circle fa-fw" />
              {'Predicted to pass'}
              <br />
              <i className="fa fa-minus-circle fa-fw" />
              {'Borderline'}
              <br />
              <i className="fa fa-times-circle fa-fw" />
              {'Not predicted to pass'}
              <br />
              <i className="fa fa-question-circle fa-fw" />
              {'No prediction'}
            </div>
            <div className="column">
              <i className="fa fa-square fa-fw" />
              {'Passed the pre-test'}
              <br />
              <i className="fa fa-square-o fa-fw" />
              {'Did not pass the pre-test'}
              <br />
              <div id="emptyTriangleLegendWrapper">
                <p className="emptyTriangle">&#x25B3;</p>
                <p>Did not pass the final</p>
              </div>
            </div>
          </div>
          <div id="studentHeader">
            <h1 id="nameHeader">{ student ? student.name : 'Select a Student' }</h1>
            {passPredictionBar}
            <h6 id="pass-prediction-text">{passPredictionText}</h6>
          </div>
        </div>
        <StudentTimeline
          timelineData={this.state.timelineData}
          defaultStart={this.state.defaultStart}
          defaultEnd={this.state.defaultEnd}
        />
        <div id="formTableContainer">
          <div id="recommendationForm">
            <label htmlFor="recommendedAction">
              {'Instructor Recommendation'}
              <select id="recommendationSelect" disabled={disabled} ref={this.recSelectRef}>
                <option value="additionalPrep">Additional Preparation</option>
                <option value="pre-test">Pre-test</option>
                <option value="finalAssessment">Final Assessment</option>
              </select>
            </label>
            <br />
            <label htmlFor="notes">
              {'Notes'}
              <textarea type="text" id="notes" disabled={disabled} ref={this.noteRef} />
            </label>
            <Button
              id="submit"
              type="button"
              bsStyle="primary"
              disabled={disabled}
              onClick={() => { this.postRecommendedAction(); }}
            >
              {'Submit'}
            </Button>
          </div>
          <Table
            rawData={this.state.recommendationData}
            headings={['SR Date', srLabel, passLabel, 'IR Date', irLabel, 'Notes']}
            headingSortKeys={['system_recommendation_time', 'system_recommendation', 'pass_prediction', 'instructor_recommendation_time', 'instructor_recommendation', '']}
            btnTitles={['System Recommendation Date', '', '', 'Instructor Recommendation Date', '', '']}
            tableData={this.state.tableData}
            createTableData={this.createTableData}
            tableClass="info"
            defaultSortParam="-system_recommendation_time"
          />
        </div>
        <ErrorPopup
          popupContent={this.state.popupContent}
          closePopup={() => this.setState({ popupContent: null })}
        />
      </div>
    );
  }
}

StudentInfo.defaultProps = {
  currentStudent: null,
  selectedListRow: null,
};

StudentInfo.propTypes = {
  currentStudent: PropTypes.instanceOf(Object),
  headers: PropTypes.instanceOf(Object).isRequired,
  selectedListRow: PropTypes.instanceOf(HTMLTableRowElement),
};

export default StudentInfo;
