import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorPopup from './ErrorPopup';
import Table from './Table';
import './StudentList.css';

// abbreviate the student's most recent recommended action to fit in the list
export function abbreviateRecommendedAction(action) {
  let abbreviation = '';
  if (action === 'Final Assessment') {
    abbreviation = 'Final Assmt.';
  } else if (action === 'Additional Preparation') {
    abbreviation = 'Add\'l Prep.';
  } else {
    abbreviation = action;
  }
  return abbreviation;
}

// generate symbols for a student's two most recent test actions
export function studentActionSymbols(actions) {
  const symbols = [];
  actions.forEach((action, i) => {
    let symbol = null;
    if (action.type === 'Final Assessment' && !action.passed) {
      symbols.push(<p key={action.begin} className="emptyTriangle">&#x25B3;</p>);
    } else if (action.type === 'Pre-test') {
      if (action.passed) {
        symbol = 'square';
      } else {
        symbol = 'square-o';
      }
      symbols.push(<i key={action.begin} className={`fa fa-${symbol} fa-fw`} />);
    }
  });
  return <div className="studentActionSymbols">{symbols}</div>;
}

// generate the student's pass prediction symbol
// TODO: check what the cutoff values are for prediction
export function studentPassPredictSymbol(passPredict) {
  let symbol = '';
  if (passPredict >= 80) {
    symbol = 'check';
  } else if (passPredict >= 60) {
    symbol = 'minus';
  } else if (!passPredict) {
    symbol = 'question';
  } else {
    symbol = 'times';
  }
  return <i className={`fa fa-${symbol}-circle fa-fw`} />;
}

class StudentList extends Component {
  constructor(props) {
    super(props);
    this.searchForm = React.createRef();
    this.createTableData = this.createTableData.bind(this);
    this.filterStudents = this.filterStudents.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.state = {
      studentData: [],
      tableData: [],
      popupContent: null,
      nameFilter: '',
      newFilter: false,
      exclamationFilter: false,
    };
    fetch(
      `${process.env.REACT_APP_DOMAIN}/learner-list/`,
      { headers: this.props.headers },
    ).then((response) => {
      if (!response.ok) {
        this.setState({ popupContent: 'Internal server error' });
      } else {
        response.json().then((students) => {
          const studentData = [];
          students.forEach((student) => {
            if (!student.actions
              || !student.actions.some(action => (action.type === 'Final Assessment' && action.passed))) {
              studentData.push(student);
            }
          });
          this.createTableData(studentData);
        }).catch(() => {
          this.setState({ popupContent: 'Internal server error' });
        });
      }
    }).catch(() => {
      this.setState({ popupContent: 'Could not connect to server' });
    });
  }

  // creates table data only for the student list table
  createTableData(studentData) {
    const tableData = [];
    let filteredStudents = studentData.filter(
      student => student.name.toLowerCase().includes(this.state.nameFilter.toLowerCase()),
    );
    if (this.state.newFilter) {
      filteredStudents = filteredStudents.filter(
        student => student.seen === false && student.system_recommendation,
      );
    }
    if (this.state.exclamationFilter) {
      filteredStudents = filteredStudents.filter(
        student => student.system_recommendation !== student.instructor_recommendation,
      );
    }
    filteredStudents.forEach((student) => {
      const rowRef = React.createRef();
      let actionNeeded = true;
      if (student.system_recommendation === student.instructor_recommendation) {
        actionNeeded = false;
      }
      const newRec = student.seen === false && student.system_recommendation;
      const studentRow = (
        <tr
          key={student.ls_learner_id}
          ref={rowRef}
          onClick={() => { this.props.selectStudent(rowRef.current, student); }}
          className={newRec ? 'unseen' : undefined}
          title={newRec ? 'New system recommendation' : undefined}
        >
          <td>{studentPassPredictSymbol(student.pass_prediction)}</td>
          <td>{student.name}</td>
          <td>
            {abbreviateRecommendedAction(student.system_recommendation)}
            {actionNeeded ? <i className="fa fa-exclamation-circle fa-fw" title="Latest system recommendation differs from the latest instructor recommendation." /> : null}
          </td>
          <td>{student.actions && studentActionSymbols(student.actions.slice().reverse())}</td>
        </tr>
      );
      tableData.push(studentRow);
    });
    this.setState({ tableData, studentData });
  }

  // filter students based on the provided query parameters and
  // generate table rows for them
  filterStudents(nameFilter) {
    this.setState({ nameFilter }, () => {
      this.createTableData(this.state.studentData);
    });
  }

  clearFilter() {
    this.searchForm.current.value = '';
    this.filterStudents('');
  }

  handleCheck(e) {
    this.setState({ [e.target.id]: e.target.checked }, () => {
      this.filterStudents(this.state.nameFilter);
    });
  }

  render() {
    return (
      <div id="studentList">
        <div className="header">
          <h2 id="studentLabel">Students</h2>
          <div id="studentSearch">
            <input
              id="studentSearchText"
              type="text"
              placeholder="Search students"
              ref={this.searchForm}
              onKeyUp={(e) => {
                this.filterStudents(e.target.value);
              }}
            />
            {this.searchForm.current
            && this.searchForm.current.value
            && (
              <button
                id="searchClear"
                type="button"
                onClick={this.clearFilter}
              >
                <i className="fa fa-times-circle-o fa-fw" />
              </button>
            )}
            <button
              id="studentSearchButton"
              type="button"
              onClick={() => this.filterStudents(this.searchForm.current.value)}
            >
              {'Search'}
            </button>
          </div>
          <div id="checkFilters">
            <label htmlFor="newFilter">
              <input type="checkbox" id="newFilter" onChange={this.handleCheck} />
              {'Only show new recommendations'}
            </label>
            <label htmlFor="exclamationFilter">
              <input type="checkbox" id="exclamationFilter" onChange={this.handleCheck} />
              <i className="fa fa-exclamation-circle fa-fw" title="Latest system recommendation differs from the latest instructor recommendation." />
            </label>
          </div>
        </div>
        <Table
          rawData={this.state.studentData}
          headings={['', 'Student', 'Rec. Action', 'Actions']}
          headingSortKeys={['pass_prediction', 'name', 'system_recommendation', '']}
          btnTitles={['', '', '', '']}
          tableData={this.state.tableData}
          createTableData={this.createTableData}
          tableClass="list"
          defaultSortParam="name"
        />
        <ErrorPopup
          popupContent={this.state.popupContent}
          closePopup={() => this.setState({ popupContent: null })}
        />
      </div>
    );
  }
}

StudentList.propTypes = {
  headers: PropTypes.instanceOf(Object).isRequired,
  selectStudent: PropTypes.func.isRequired,
};

export default StudentList;
