import auth0 from 'auth0-js';
import AUTH_CONFIG from './auth0-variables';
import history from '../history';

class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: 'https://api.tgcheckpoint.com',
    responseType: 'token id_token',
    scope: 'openid profile',
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.renewToken = this.renewToken.bind(this);
    this.scheduleRenewal = this.scheduleRenewal.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getProfile = this.getProfile.bind(this);
    window.addEventListener('load', this.scheduleRenewal); // schedule token renewal on page load
  }

  userProfile;

  tokenRenewalTimeout;

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        history.replace('/');
      } else if (err) {
        history.replace('/');
        console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  // attempts to renew the user token through auth0
  renewToken() {
    this.auth0.checkSession({},
      (err, authResult) => {
        if (err) {
          console.log(err);
          alert(`Error: ${err.error}. Check the console for further details.`);
        } else {
          this.setSession(authResult);
        }
      });
  }

  // schedules the user token to be renewed as soon as it expires
  scheduleRenewal() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const delay = expiresAt - Date.now();
    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken();
      }, delay);
    }
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    this.scheduleRenewal();
  }

  getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('No access token found');
    }
    return accessToken;
  }

  getHeaders() {
    const accessToken = this.getAccessToken();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };
    return headers;
  }

  getProfile(cb) {
    const accessToken = this.getAccessToken();
    this.auth0.client.userInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    clearTimeout(this.tokenRenewalTimeout); // stop token renewal on logout
    this.userProfile = null;
    this.auth0.logout({
      returnTo: process.env.REACT_APP_CALLBACK_DOMAIN,
      clientID: AUTH_CONFIG.clientId,
    });
  }

  isAuthenticated() {
    // Check whether the current time is past the 
    // access token's expiry time
    const currentTime = new Date().getTime();
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return currentTime < expiresAt;
  }
}

export default Auth;
