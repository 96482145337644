import React from 'react';
import Popup from 'reactjs-popup';
import './ErrorPopup.css';

function ErrorPopup(props) {
  return(
    <Popup
      open={props.popupContent}
      onClose={() => props.closePopup()}
      contentStyle={{height: '15vh', width: '15vw'}}
    >
      <div className="popup">
        <div className="popupHeader">
          Error
        </div>
        <div className="popupContent">
          {props.popupContent}
        </div>
        <button type="button" className="popupClose" onClick={() => props.closePopup()}>Close</button>
      </div>
    </Popup>
  );
}

export default ErrorPopup;
