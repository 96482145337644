import React from 'react';
import { Route, Router } from 'react-router-dom';
import Auth from './auth/Auth';
import Auth0Callback from './auth0callback/Auth0Callback';
import Authentication from './Authentication';
import history from './history';

const auth = new Auth();

const handleAuthentication = ({ location }) => {
  if (location.key) {
    history.replace('/');
  } else if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
};

const makeMainRoutes = () => (
  <Router history={history}>
    <div>
      <Route path="/" render={props => <Authentication auth={auth} {...props} />} />
      <Route path="/callback" render={(props) => { handleAuthentication(props); return (<Auth0Callback {...props} />); }} />
    </div>
  </Router>
);

export default makeMainRoutes;
