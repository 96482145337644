import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Table.css';

class Table extends Component {
  constructor(props) {
    super(props);
    this.sortTableData = this.sortTableData.bind(this);
    this.state = {
      sortParam: this.props.defaultSortParam,
    };
  }

  // changes sort parameter based on the table header clicked
  // if already sorting by that parameter, clicking reverses it
  sortTableData(newSortParam) {
    function compare(a, b) {
      const aSort = a[newSortParam];
      const bSort = b[newSortParam];
      if ((!aSort && bSort) || aSort < bSort) {
        return -1;
      }
      if ((aSort && !bSort) || aSort > bSort) {
        return 1;
      }
      return 0;
    }
    const data = this.props.rawData.slice();
    data.sort(compare);
    let sortParam = newSortParam;
    if (newSortParam === this.state.sortParam) {
      sortParam = `-${newSortParam}`;
      data.reverse();
    }
    this.props.createTableData(data);
    this.setState({ sortParam });
  }

  render() {
    const headings = [];
    const cls = this.props.tableClass;
    this.props.headings.forEach((heading, i) => {
      const sortKey = this.props.headingSortKeys[i];
      let innerHeading;
      // if there is a corresponding sort key for the heading,
      // make the heading a button with a sorting icon
      if (sortKey) {
        innerHeading = (
          <button
            type="button"
            title={this.props.btnTitles[i]}
            onClick={() => this.sortTableData(sortKey)}
          >
            {heading}
            <i className="fa fa-sort fa-fw" />
          </button>
        );
      } else {
        innerHeading = <p>{heading}</p>;
      }
      headings.push(
        <th key={heading} className={heading ? heading.replace(/ |\.|\n/g, '') : 'PassPredictIcon'}>
          <div className={`tableHeader ${cls}TableHeader`}>
            {innerHeading}
          </div>
        </th>,
      );
    });

    return (
      <div className={`tableContainer ${cls}TableContainer`}>
        <div className={`innerTable ${cls}InnerTable`}>
          <table className="table table-striped">
            <thead>
              <tr className="headerRow">
                {headings}
              </tr>
            </thead>
            <tbody className="tableBody">
              {this.props.tableData}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

Table.defaultProps = {
  tableData: [],
};

Table.propTypes = {
  createTableData: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.element),
  headings: PropTypes.arrayOf(PropTypes.string).isRequired,
  btnTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  headingSortKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableClass: PropTypes.string.isRequired,
  rawData: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultSortParam: PropTypes.string.isRequired,
};

export default Table;
