import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import './App.css';
import StudentList from './StudentList';
import StudentInfo from './StudentInfo';

// PureComponent so that it doesn't rerender when the parent in Authentication
// checks that the user is authenticated
class App extends PureComponent {
  constructor(props) {
    super(props);
    this.headers = this.props.auth.getHeaders();
    this.selectStudent = this.selectStudent.bind(this);
    this.state = {
      currentStudent: null,
      selectedListRow: null,
    };
  }

  selectStudent(row, student) {
    const currentRow = this.state.selectedListRow;
    if (currentRow) {
      currentRow.style = {};
      currentRow.id = '';
    }
    row.id = 'currentStudent';
    row.className = '';
    row.title = '';
    this.setState({ currentStudent: student, selectedListRow: row });
  }

  render() {
    return (
      <div className="App">
        <div id="headerContainer">
          <h1 className="brand">
            TutorGen
            <sup>&reg;</sup>
            &nbsp;
            CheckPoint
          </h1>
          <Button
            id="logoutBtn"
            bsStyle="primary"
            className="btn-margin"
            onClick={this.props.auth.logout}
          >
            <i className="fa fa-sign-out fa-fw" />
            Logout
          </Button>
        </div>
        <div id="studentContainer">
          <StudentList
            headers={this.headers}
            selectStudent={this.selectStudent}
          />
          <div
            id="divider"
            role="separator"
          />
          <StudentInfo
            headers={this.headers}
            currentStudent={this.state.currentStudent}
            selectedListRow={this.state.selectedListRow}
          />
        </div>
      </div>
    );
  }
}

App.propTypes = {
  auth: PropTypes.instanceOf(Object).isRequired,
};

export default App;
