import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Timeline from 'react-calendar-timeline';
import './StudentTimeline.css';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getMinUnit } from 'react-calendar-timeline/src/lib/utility/calendar';
import { defaultTimeSteps } from 'react-calendar-timeline/src/lib/default-config';

// returns an object used for determing the scale/speed for
// moving the timeline left and right
export function calculateMoveUnit(timelineUnit) {
  const moveUnit = {};
  if (timelineUnit === 'month') {
    moveUnit.amount = 5;
    moveUnit.type = 'day';
  } else if (timelineUnit === 'day') {
    moveUnit.amount = 2;
    moveUnit.type = 'day';
  } else if (timelineUnit === 'hour') {
    moveUnit.amount = 2;
    moveUnit.type = 'hour';
  }
  return moveUnit;
}

// returns an object used for determing the scale/speed for
// zooming the timeline in and out
export function calculateZoomUnit(timelineUnit) {
  const zoomUnit = {};
  if (timelineUnit === 'month') {
    zoomUnit.amount = 5;
    zoomUnit.type = 'day';
  } else if (timelineUnit === 'day') {
    zoomUnit.amount = 1;
    zoomUnit.type = 'day';
  } else if (timelineUnit === 'hour') {
    zoomUnit.amount = 1;
    zoomUnit.type = 'hour';
  }
  return zoomUnit;
}

class StudentTimeline extends Component {
  constructor(props) {
    super(props);

    this.getTimelineUnit = this.getTimelineUnit.bind(this);
    this.setVisibleTimes = this.setVisibleTimes.bind(this);
    this.incrementTimelineBounds = this.incrementTimelineBounds.bind(this);
    this.timelineGoLeft = this.timelineGoLeft.bind(this);
    this.timelineGoRight = this.timelineGoRight.bind(this);
    this.timelineZoomIn = this.timelineZoomIn.bind(this);
    this.timelineZoomOut = this.timelineZoomOut.bind(this);
    this.timelineReset = this.timelineReset.bind(this);
    this.executeWaitLoop = this.executeWaitLoop.bind(this);
    this.itemRenderer = this.itemRenderer.bind(this);

    this.clickTimer = null;
    this.holdTimer = null;
    this.timelineRef = React.createRef();

    window.addEventListener('resize', () => this.forceUpdate()); // rerender on window resize
    document.addEventListener('mouseup', () => { // stops timeline controls when mouse is released
      if (this.clickTimer) { // prevent loop from executing
        clearTimeout(this.clickTimer);
      }
      if (this.holdTimer) { // stop currently executing loop
        clearInterval(this.holdTimer);
      }
    });

    this.state = {
      timelineStart: this.props.defaultStart,
      timelineEnd: this.props.defaultEnd,
      visibleMonths: moment(this.props.defaultEnd).diff(this.props.defaultStart, 'months'),
    };
  }

  // reset the timeline position when a new student is selected
  // and set the tooltips for the items
  componentDidUpdate(prevProps) {
    if (prevProps.timelineData !== this.props.timelineData) {
      this.timelineReset();
    }
  }

  // get the current timeline increment e.g. month, day, etc.
  getTimelineUnit(timelineStart, timelineEnd) {
    const timelineWidth = this.timelineRef.current && this.timelineRef.current.state.width;
    return getMinUnit((timelineEnd - timelineStart), timelineWidth, defaultTimeSteps);
  }

  // set the start and end dates for the timeline's visual range
  setVisibleTimes(visibleTimeStart, visibleTimeEnd) {
    this.setState({
      timelineStart: visibleTimeStart,
      timelineEnd: visibleTimeEnd,
      visibleMonths: moment(this.state.timelineEnd).diff(this.state.timelineStart, 'months'),
    });
  }

  // increment or decrement the start and end bounds of the timeline
  // by an amount of units depending on the start and end functions
  incrementTimelineBounds(unit, startFunction, endFunction) {
    this.setVisibleTimes(
      moment(this.state.timelineStart)[startFunction](unit.amount, unit.type).valueOf(),
      moment(this.state.timelineEnd)[endFunction](unit.amount, unit.type).valueOf(),
    );
  }

  // make the timeline go left towards past dates
  timelineGoLeft(timelineUnit) {
    const moveUnit = calculateMoveUnit(timelineUnit);
    this.incrementTimelineBounds(moveUnit, 'subtract', 'subtract');
  }

  // make the timeline go right towards future dates
  timelineGoRight(timelineUnit) {
    const moveUnit = calculateMoveUnit(timelineUnit);
    this.incrementTimelineBounds(moveUnit, 'add', 'add');
  }

  // zoom in by narrowing the timeline bounds unless seconds would be the displayed unit
  timelineZoomIn() {
    const timelineUnit = this.getTimelineUnit(
      this.state.timelineStart.valueOf(), this.state.timelineEnd.valueOf(),
    );
    const zoomUnit = calculateZoomUnit(timelineUnit);
    const timelineStart = moment(this.state.timelineStart).add(zoomUnit.amount, zoomUnit.type)
      .valueOf();
    const timelineEnd = moment(this.state.timelineEnd).subtract(zoomUnit.amount, zoomUnit.type)
      .valueOf();
    const newTimelineUnit = this.getTimelineUnit(timelineStart, timelineEnd);
    if (newTimelineUnit !== 'second') {
      this.setVisibleTimes(timelineStart, timelineEnd);
    } else {
      clearInterval(this.timer);
    }
  }

  // zoom out by expanding the timeline bounds
  timelineZoomOut() {
    const timelineUnit = this.getTimelineUnit(
      this.state.timelineStart.valueOf(), this.state.timelineEnd.valueOf(),
    );
    const zoomUnit = calculateZoomUnit(timelineUnit);
    this.incrementTimelineBounds(zoomUnit, 'subtract', 'add');
  }

  // return to the default timeline range
  timelineReset() {
    this.setVisibleTimes(this.props.defaultStart, this.props.defaultEnd);
  }

  // executes the given function once, waits a period of time, then executes
  // the function in a loop while the mouse is held
  executeWaitLoop(timelineFunction, timelineUnit) {
    timelineFunction(timelineUnit);
    this.clickTimer = setTimeout(() => {
      this.holdTimer = setInterval(() => { timelineFunction(timelineUnit); }, 100);
    }, 500);
  }

  // allows customization of timeline items
  itemRenderer({ item, getItemProps }) {
    let content;
    if (item.group === 1) { // session
      if (this.state.visibleMonths < 4) {
        content = (
          <div {...getItemProps(item.itemProps)}>
            <div className="extraTooltipSpace" />
          </div>
        );
      } else {
        content = <div {...getItemProps(item.itemProps)} />;
      }
    } else { // pretest or final
      // only pull the style elements necessary for dynamic positioning
      const itemStyle = getItemProps(item.itemProps).style;
      content = (
        <div
          className="itemContainer"
          style={{
            left: itemStyle.left,
            top: itemStyle.top,
            height: itemStyle.height,
            lineHeight: itemStyle.lineHeight,
            width: itemStyle.width,
          }}
        >
          {item.content}
        </div>
      );
    }
    // wrap the timeline item in a bootstrap tooltip container
    return (
      <OverlayTrigger
        placement="top"
        overlay={(
          <Tooltip
            id={`${item.id}ToolTip`}
          >
            {item.toolTip}
          </Tooltip>
        )}
      >
        {content}
      </OverlayTrigger>
    );
  }

  render() {
    const visibleTimeStart = this.state.timelineStart.valueOf();
    const visibleTimeEnd = this.state.timelineEnd.valueOf();
    const timelineUnit = this.getTimelineUnit(visibleTimeStart, visibleTimeEnd);
    return (
      <div id="StudentTimeline">
        <div id="timelineControls">
          <button id="timelineLeft" type="button" onMouseDown={() => this.executeWaitLoop(this.timelineGoLeft, timelineUnit)}>{'<'}</button>
          <button type="button" onMouseDown={() => this.executeWaitLoop(this.timelineGoRight, timelineUnit)}>{'>'}</button>
          <button type="button" onMouseDown={() => this.executeWaitLoop(this.timelineZoomIn)}><i className="fa fa-search-plus fa-fw" /></button>
          <button type="button" onMouseDown={() => this.executeWaitLoop(this.timelineZoomOut)}><i className="fa fa-search-minus fa-fw" /></button>
          <button id="timelineDefault" type="button" onClick={this.timelineReset}>Return to Default View</button>
        </div>
        <Timeline
          ref={this.timelineRef}
          groups={[{ id: 1, title: 'Sessions' }, { id: 2, title: 'Pre-test' }, { id: 3, title: 'Final' }]}
          items={this.props.timelineData}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          sidebarWidth={Math.max((window.innerWidth / 100) * 5, 80)}
          lineHeight={(window.innerHeight / 100) * 3}
          minZoom={6 * 60 * 60 * 1000}
          maxZoom={31 * 24 * 60 * 60 * 1000 * 2}
          onTimeChange={this.setVisibleTimes}
          canMove={false}
          canResize={false}
          canChangeGroup={false}
          sidebarContent={timelineUnit.charAt(0).toUpperCase() + timelineUnit.slice(1)}
          itemRenderer={this.itemRenderer}
        />
      </div>
    );
  }
}

StudentTimeline.propTypes = {
  timelineData: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultStart: PropTypes.instanceOf(Object).isRequired,
  defaultEnd: PropTypes.instanceOf(Object).isRequired,
};

export default StudentTimeline;
