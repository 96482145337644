import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import App from './App';
import './ErrorPopup.css';
import './authentication.css';

const INACTIVITY_LIMIT_MS = 600000;
const BASE_SECONDS_TO_LOGOUT = 60;

class Authentication extends Component {
  constructor(props) {
    super(props);
    const isAuthenticated = this.props.auth.isAuthenticated();
    this.logoutCountdown = this.logoutCountdown.bind(this);
    this.resetActivity = this.resetActivity.bind(this);
    this.inactivityTimer = isAuthenticated
      && setInterval(this.logoutCountdown, INACTIVITY_LIMIT_MS);
    this.popupTimer = null;
    this.state = {
      isAuthenticated,
      popupOpen: false,
      secondsToLogout: BASE_SECONDS_TO_LOGOUT,
    };
  }

  componentDidUpdate() {
    const isAuthenticated = this.props.auth.isAuthenticated();
    if (this.state.isAuthenticated !== isAuthenticated) {
      this.setState({ isAuthenticated });
    }
  }

  // shows a popup that counts down to logout
  logoutCountdown() {
    clearInterval(this.inactivityTimer);
    this.popupTimer = setInterval(() => {
      const currentSeconds = this.state.secondsToLogout;
      if (currentSeconds === 0) {
        this.props.auth.logout();
      } else {
        this.setState(prevState => ({
          secondsToLogout: prevState.secondsToLogout - 1,
        }));
      }
    }, 1000);
    this.setState({ popupOpen: true });
  }

  // resets timers and state so that the user is considered "active"
  resetActivity() {
    clearInterval(this.inactivityTimer);
    clearInterval(this.popupTimer);
    this.inactivityTimer = setInterval(this.logoutCountdown, INACTIVITY_LIMIT_MS);
    if (this.state.popupOpen) {
      this.popupTimer = null;
      this.setState({ popupOpen: false, secondsToLogout: BASE_SECONDS_TO_LOGOUT });
    }
  }

  goTo(route) {
    this.props.history.replace(`/${route}`);
  }

  render() {
    if (this.state.isAuthenticated) {
      return (
        <div
          role="presentation"
          onMouseMove={this.resetActivity}
          onMouseDown={this.resetActivity}
          onTouchStart={this.resetActivity}
          onClick={this.resetActivity}
          onScroll={this.resetActivity}
          onKeyPress={this.resetActivity}
        >
          <App auth={this.props.auth} />
          <Popup
            open={this.state.popupOpen}
            contentStyle={{ height: '15vh', width: '15vw' }}
          >
            <div className="popup">
              <div className="popupHeader">
                {'Inactive'}
              </div>
              <div className="popupContent">
                {`You will be logged out in ${this.state.secondsToLogout} seconds.`}
              </div>
            </div>
          </Popup>
        </div>
      );
    }
    return (
      <div>
        {
          !this.state.isAuthenticated && (
            <div id="welcome">
              <h1 className="animated fadeInDown delay-07s">
                {'TutorGen'}
                <sup>&reg;</sup>
                &nbsp;
                {'CheckPoint'}
              </h1>
              <h4>
                {'Please log in to continue'}
              </h4>
              <Button
                id="qsLoginBtn"
                bsStyle="primary"
                className="btn-margin"
                onClick={this.props.auth.login}
              >
                {'Log In'}
              </Button>
            </div>
          )
        }
      </div>
    );
  }
}

Authentication.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  auth: PropTypes.instanceOf(Object).isRequired,
};

export default Authentication;
